import { ClientConfigService } from '../services/client-config.service';

declare global {
  interface Window {
    hj: any;
    _hjSettings: { hjid: number | string; hjsv: number };
  }
}
export function hotJarInit(clientConfigService: ClientConfigService) {
  return (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      clientConfigService.hotjar().subscribe(config => {
        if (config.enabled === true) {
          window.hj = function (...args) {
            (window.hj.q = window.hj.q || []).push(args);
          };
          window._hjSettings = { hjid: config.id, hjsv: config.sv };
          const hjUrl = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
          const a = document.getElementsByTagName('head')[0];
          const r = document.createElement('script');
          r.async = true;
          r.src = hjUrl;
          a.appendChild(r);
          r.addEventListener('load', function () {
            console.log('HotJar loaded successfully', config);
            resolve();
          });
          r.addEventListener('error', function (event: Event) {
            console.log('HotJar not loaded', config, event);
            resolve();
          });
        } else {
          console.log('HotJar disabled', config);
          resolve();
        }
      });
    });
  };
}
